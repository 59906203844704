import React from "react";
import { withContext } from "~context";
import * as styles from "./index.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { PageLayout } from "~components/templates/page-layout";
import { PipeDriveForm } from "~components/utils/pipedrive-form";
import { SectionLayout } from "~components/templates/section-layout";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";

const Component: React.FC = () => {
  const CONTACT_FORM_ID =
    "2VrF8TwHsoWuBNLF2ujgtsJl4jhaTmjJehw1FjEOUIQ8wgwbKBfFUlETYL07oEZ6H";
  const ALTERNATIVE_GOOGLEFORM_URL = "https://forms.gle/B7c1hGh9i4rmJnEg6";

  const data: GatsbySampleReportPageQuery = useStaticQuery(graphql`
    query SampleReportPage {
      catch: file(
        relativePath: { eq: "assessment/sample_report_catch.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <>
      <PriceDocBanner />
      <PageLayout
        paddingPattern="fit-header"
        meta={{
          title: "セキュリティ診断 サンプル報告書ダウンロード",
          description:
            "Flatt Securityのセキュリティ診断のサンプル報告書をダウンロード可能です。",
        }}
      >
        <div className={styles.SampleReport}>
          <div className={styles.SampleReport_Head}>
            <SectionLayout>
              <OptionalGatsbyImage
                className={styles.SampleReport_Catch}
                data={data.catch}
                alt=""
              />
              <PipeDriveForm formID={CONTACT_FORM_ID} />
              <p className={styles.SampleReport_Escape}>
                Internet
                Explorerをお使いの場合など、お問い合わせフォームが表示されない場合は
                <a
                  className={styles.SampleReport_EscapeLink}
                  href={ALTERNATIVE_GOOGLEFORM_URL}
                >
                  別ページ
                </a>
                からお問い合わせください。
              </p>
            </SectionLayout>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default withContext(Component);
